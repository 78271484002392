import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import ShareDialog from '../../components/ShareDialog'
import Response from '../../components/Response'
import EventDialog from '../../components/EventDialog'
import ReadOnlySlider from '../../components/ReadOnlySlider';
import Legend from '../../components/Legend'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import ReactGA from 'react-ga'
import { selectIssue, saveIssueTitle, saveIssueKey, saveAPIToken, selectOption, saveStereotypes } from '../../actions';


const mapStateToProps = (state) => {
    const { setMediaFilter, selectOption, selectIssue, saveOpinion, saveIssueTitle, saveAPIToken, saveStereotypes } = state;
    return {
        opinion: saveOpinion,
        isMobile: "MOBILE" === setMediaFilter,
        option: selectOption,
        issue: selectIssue,
        issueTitle: saveIssueTitle,
        token: saveAPIToken,
        stereotypes: saveStereotypes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        select: (issue) => dispatch(selectIssue(issue)),
        saveIssueTitle: (issue) => dispatch(saveIssueTitle(issue)),
        saveIssueKey: (key) => dispatch(saveIssueKey(key)),
        save_token: (token) => dispatch(saveAPIToken(token)),
        selectoption: (option) => dispatch(selectOption(option)),
        save_stereotypes: (stereotype) => dispatch(saveStereotypes(stereotype))
    }
}


class CompleteScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 'star',
            statistics: {
                opinionCount: 0, 
                genderCount: {'여성': 0, '남성': 0, '그 외': 0, '대답을 원치 않음': 0}, 
                ageCount: {'10대': 0, '20대': 0, '30대': 0, '40대': 0, '50대': 0, '60대': 0, '70대 이상': 0}, 
                stanceCount: {'보수': 0, '중도보수': 0, '중도': 0, '중도진보': 0, '진보': 0, '잘 모름/대답을 원치 않음': 0}
            },
            filters: ['정치성향별', '성별', '연령대별'],
            buttonIsHovered: false,
            buttonIsClicked: false,
            filter: ['정치성향별'],
            filterItem: '',
            mobileFilterPoliticalStance: ['모든 정치 성향'],
            mobileFilterGender: ['모든 성별'],
            mobileFilterAge: ['모든 연령대'],
            mobileFilterStance: ['입장별'],
            width: window.width,
            height: window.height,
            issueTitle: '',
            drawData: null,
            hoverTop: 0,
            hoverLeft: 0,
            hoverId: '',
            clickedId: '',
            opinions: [],
            idx: -1,
            open: false, // snackbar open bool
            loading: true,
            prevOpinion: false,
            copied: false,
            isEditing: false,
            newStance: -1,
            myOpinion: {},
            myOpinionIdx: -1,
            enableMobileView: false,
            differents: [],
            opposites: [],
            similarities: [],
            clickedOpinions: [],
            shineFilter: false
        }
        this.filterGraph = this.filterGraph.bind(this);
        this.updateFilterAge = this.updateFilterAge.bind(this);
        this.updateFilterGender = this.updateFilterGender.bind(this);
        this.updateFilterPoliticalStance = this.updateFilterPoliticalStance.bind(this);
        this.updateFilterStance = this.updateFilterStance.bind(this);
        this.setButtonHovered = this.setButtonHovered.bind(this);
        this.setButtonClicked = this.setButtonClicked.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        this.onLikeClickHandler = this.onLikeClickHandler.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.createLog = this.createLog.bind(this)
        this.checkSharedIdentity = this.checkSharedIdentity.bind(this);
        this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
        this.ongoingTimers = {}
        this.initiateTimer = this.initiateTimer.bind(this)
        this.updateFocus = this.updateFocus.bind(this)
    }

    setOpen(bool) {
        this.setState({
            open: bool
        })
    }

    filterGraph(filter, statistics) {
        const filterList = (
            filter[0] ==="정치성향별" ? [['보수', 'ex-r'],['중도보수', 'r'],['중도', 'n'],['중도진보', 'l'],['진보', 'ex-l'],['잘 모름/대답을 원치 않음', 'not']]
            : filter[0] ==="성별" ? [['여성', 'female'], ['남성', 'male'], ['그 외', 'un'], ['대답을 원치 않음', 'not']]
            : [['10대', 'g10'], ['20대', 'g20'],['30대', 'g30'],['40대', 'g40'],['50대', 'g50'],['60대', 'g60'],['70대 이상', 'g70']]
        )
        const statsKey = {
            '정치성향별': 'stanceCount',
            '성별': 'genderCount',
            '연령대별': 'ageCount'
        }[filter[0]]
        const filterType = filter[0]
        const { filterItem } = this.state
        return (
            (
                <div className="filter-group">
                    <div className="filter-text" onClick={(e) => this.graphOnClickHandler(filterType, '', e)}>
                        총 {statistics.opinionCount}명 중
                    </div>
                    {
                        filterList.map(filter => {
                            return (
                            <div className="filter-item" key={filter[0]} onClick={(e) => this.graphOnClickHandler(filterType, filter[0], e)}>
                                <div className={`${filter[1]} circular filter`} />
                                <div className={`filter-text ${filterItem === filter[0] ? 'active' : ''}`}>
                                    {filter[0]} {statistics[statsKey][filter[0]]}명
                                </div>
                            </div>
                            )})
                    }
                </div>
            )
        )
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setOpen(false);
    };

    createLog (eventName, payload, time = Date.now()) {
        const { token, match } = this.props
        fetch(`${process.env.REACT_APP_URL}/dev/logs`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify([{
                page: `result/${match.params.key}`,
                event: eventName,
                timestamp: time,
                payload: payload,
                condition: 'starview'
            }])
        })
    }

    updateFilterPoliticalStance(event) {
        console.log('politicalstance filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterPoliticalStance', {
            changedTo: event.target.value
        })   
        this.setState({
            mobileFilterPoliticalStance: event.target.value
        })
    }

    updateFilterGender(event) {
        console.log('gender filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterGender', {
            changedTo: event.target.value
        })
        this.setState({
            mobileFilterGender: event.target.value
        })
    }

    updateFilterAge(event) {
        console.log('age filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterAge', {
            changedTo: event.target.value
        })
        this.setState({
            mobileFilterAge: event.target.value
        })
    }

    updateFilterStance(event) {
        console.log('stance filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterStance', {
            changedTo: event.target.value
        })
        this.setState({
            mobileFilterStance: [event.target.value]
        })
    }
    
    initiateTimer (opinionId, time, stance, argument, recommended = {}, eventName = '') {
        console.log(recommended)
        const timer = setTimeout(() => {
            this.createLog('opinionViewed', {
                type: eventName,
                condition: 'starview',
                opinionId: opinionId,
                opinionStance: stance,
                opinionArgument: argument,
                myStance: this.state.myOpinion.stance,
                ...recommended
            }, time)
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }
    
    updateFocus (inView, entry, opinionId, stance, argument) {
        const { focusedOpinionId, myOpinion } = this.state
        if (inView) {
            if (myOpinion) {
                this.ongoingTimers[opinionId] = this.initiateTimer(opinionId, Date.now(), stance, argument)
            }
            this.setState({
                focusedOpinionId: opinionId
            })
        }

        if (!inView && opinionId === focusedOpinionId) {
            const cancelTimer = this.ongoingTimers[opinionId]
            if (cancelTimer) {
                cancelTimer()
            }
            this.setState({
                focusedOpinionId: ''
            })
        }
    }

    onMouseEnterHandler(data, top, left, idx) {
        const { opposites, differents, similarities } = this.state
        const recommended = {
            opposite: opposites.includes(data._id),
            different: differents.includes(data._id),
            similar: similarities.includes(data._id)
        }
        this.ongoingTimers[data._id] = this.initiateTimer(data._id, Date.now(), data.stance, data.argument, recommended, 'hovered')
        this.setState({
            drawData: data,
            hoverTop: top,
            hoverLeft: left,
            hoverId: data._id,
            idx: idx
        })
    }

    onStarClickHandler(id) {
        const { opinions, clickedOpinions, opposites, differents, similarities } = this.state
        // this.createLog('StarClicked', {
        //     opinionId: id
        // })

        const recommended = {
            opposite: opposites.includes(id),
            different: differents.includes(id),
            similar: similarities.includes(id)
        }

        const matchedOpinion = opinions.find((o) => { return o._id === id})

        if (this.ongoingTimers[id]) {
            this.ongoingTimers[id]()
        }

        this.ongoingTimers[id] = this.initiateTimer(id, Date.now(), matchedOpinion.stance, matchedOpinion.argument, recommended, 'clicked')

        // console.log(opinions)
        // console.log(opinions.find((o) => { return o._id === id}))
        this.setState({
            clickedId: id,
            opposites: matchedOpinion.opposites || [],
            differents: matchedOpinion.differents || [],
            similarities: matchedOpinion.similarities || [],
            clickedOpinions: clickedOpinions.concat(id)
        })
    }

    onMouseLeaveHandler() {
        const { hoverId, clickedId } = this.state;

        const cancelTimer = this.ongoingTimers[hoverId]
        if (cancelTimer) {
            cancelTimer()
        }

        console.log('mouse left', hoverId, clickedId)
        if ( hoverId !== clickedId ) {
            this.setState({
                drawData: null,
                hoverTop: 0,
                hoverLeft: 0,
                hoverId: null,
            })
        }
    }

    onUpdateOpinionClick = () => {
        const { prevOpinion } = this.state
        if (prevOpinion) {
            this.props.history.push('/UpdateOpinion')
        } else {
            const UA = window.navigator.userAgent.toLowerCase()
            if (navigator.userAgent.includes('wv') ||
                    (/iphone|ipod|ipad/.test(UA) && !/safari/.test(UA))){
                    alert(`카카오톡이나 페이스북 등 앱에서 접속하신 경우 카카오 계정 로그인 등 일부 기능이 동작하지 않을 수 있습니다. \n오른쪽 위 메뉴 (안드로이드) 혹은 오른쪽 아래 공유하기 (iOS) 에서 다른 브라우저로 접속해주세요!`)
                }
            this.props.history.push('/consent')
        }
    }

    onSeeOtherIssueClick = () => {
        const { prevOpinion } = this.state
        if (prevOpinion) {
            this.props.history.push('/NewComplete')
        } else {
            this.props.history.push('/other')
        }
    }

    onFilterButtonClick(type, event) {
        const { stereotypes } = this.props
        this.setState({
            shineFilter: true
        })
        if (type === 'mine') {
            const { myOpinion } = this.state
            this.setState({
                mobileFilterAge: [myOpinion.author.age],
                mobileFilterGender: [myOpinion.author.gender],
                mobileFilterPoliticalStance: [myOpinion.author.politicalStance]
            })
            this.createLog('seeMyGroup', {
                condition: 'starview',
                mobileFilterAge: [myOpinion.author.age],
                mobileFilterGender: [myOpinion.author.gender],
                mobileFilterPoliticalStance: [myOpinion.author.politicalStance]
            })
        } else if (type === 'pro') {
            this.setState({
                mobileFilterAge: stereotypes.pro.age,
                mobileFilterGender: stereotypes.pro.gender,
                mobileFilterPoliticalStance: stereotypes.pro.politicalStance
            })
            this.createLog('seeProGroup', {
                condition: 'starview',
                mobileFilterAge: stereotypes.pro.age,
                mobileFilterGender: stereotypes.pro.gender,
                mobileFilterPoliticalStance: stereotypes.pro.politicalStance
            })
        } else if (type === 'con') {
            this.setState({
                mobileFilterAge: stereotypes.con.age,
                mobileFilterGender: stereotypes.con.gender,
                mobileFilterPoliticalStance: stereotypes.con.politicalStance
            })
            this.createLog('seeConGroup', {
                condition: 'starview',
                mobileFilterAge: stereotypes.con.age,
                mobileFilterGender: stereotypes.con.gender,
                mobileFilterPoliticalStance: stereotypes.con.politicalStance
            })
        } else if (type === 'all') {
            this.setState({
                mobileFilterAge: ['모든 연령대'],
                mobileFilterGender: ['모든 성별'],
                mobileFilterPoliticalStance: ['모든 정치 성향']
            })
            this.createLog('seeAllGroup', {
                condition: 'starview',
                mobileFilterAge: ['모든 연령대'],
                mobileFilterGender: ['모든 성별'],
                mobileFilterPoliticalStance: ['모든 정치 성향']
            })
        }
        setTimeout(() => {
            this.setState({
                shineFilter: false
            })
        }, 3000)

    }

    resultHeader(filter, filters) {
        const { issueTitle, isMobile, token, stereotypes } = this.props;
        const { value, mobileFilterPoliticalStance, mobileFilterGender, mobileFilterAge, mobileFilterStance, prevOpinion, enableMobileView, statistics, shineFilter} = this.state;
        const politicalStance = [['보수', 'ex-r'],['중도보수', 'r'],['중도', 'n'],['중도진보', 'l'],['진보', 'ex-l'],['잘 모름/대답을 원치 않음', 'not']];
        const gender =  [['여성', 'female'], ['남성', 'male'], ['그 외', 'un']];
        const age = [['10대', 'g10'], ['20대', 'g20'],['30대', 'g30'],['40대', 'g40'],['50대', 'g50'],['60대', 'g60'],['70대 이상', 'g70']];
        const stanceList = [[1,'매우 반대'],[2, '반대'],[3, '중립'],[4, '찬성'],[5, '매우 찬성']];

        return(
            <div className="resultscreen-header">
                <div className="result-header text-center">
                    <div className="issue-title">
                        { issueTitle }
                    </div>
                    {
                        !prevOpinion && 
                        <div className="result-action">
                            <span onClick={this.onUpdateOpinionClick} className="white underline link">{prevOpinion ? (prevOpinion.argument ? '이 이슈에 대한 내 생각 수정하기' : '이 이슈에 대한 내 생각 남기기' ) : '내 생각 남기기'}</span>
                        </div>
                    }
                    <div className="result-action" onClick={this.onSeeOtherIssueClick}>
                        <span onClick={this.onSeeOtherIssueClick} className="white underline link">다른 이슈 결과보기</span>
                    </div>
                </div>
                {
                    isMobile &&
                    <div>
                        <div className="filter-button-wrapper">
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={mobileFilterPoliticalStance}
                                            onChange={this.updateFilterPoliticalStance}
                                            input={<Input id="select-multiple"/>}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "정치성향별"
                                                    else return mobileFilterPoliticalStance[0]
                                                }
                                            }
                                            >
                                                <MenuItem value='모든 정치 성향'>
                                                    모든 정치 성향
                                                </MenuItem>
                                            {
                                                politicalStance.map(stance => (
                                                    <MenuItem key={stance[0]} value={stance[0]}>
                                                        {stance[0]}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={mobileFilterGender}
                                            onChange={this.updateFilterGender}
                                            input={<Input id="select-multiple" />}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "성별"
                                                    else return mobileFilterGender[0]
                                                }
                                            }
                                            >
                                                <MenuItem value='모든 성별'>
                                                    모든 성별
                                                </MenuItem>
                                            {gender.map(g => (
                                                <MenuItem key={g[0]} value={g[0]}>
                                                    {g[0]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="filter-button-wrapper">
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={mobileFilterAge}
                                            onChange={this.updateFilterAge}
                                            input={<Input id="select-multiple" />}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "연령대별"
                                                    else return mobileFilterAge[0]
                                                }
                                            }
                                            >
                                                <MenuItem value='모든 연령대'>
                                                    모든 연령대
                                                </MenuItem>
                                            {age.map(a => (
                                                <MenuItem key={a[0]} value={a[0]}>
                                                    {a[0]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={'입장별'}
                                            onChange={this.updateFilterStance}
                                            input={<Input id="select-multiple" />}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "입장별"
                                                    else {
                                                        if (mobileFilterStance[0] === '입장별') return "입장별"
                                                        else if (mobileFilterStance[0] === '전체') return "전체"
                                                        else return stanceList[mobileFilterStance[0]-1][1]
                                                    }
                                                }
                                            }
                                            >
                                                <MenuItem value='전체'>
                                                    전체
                                                </MenuItem>
                                            {stanceList.map(a => (
                                                <MenuItem key={a[0]} value={a[0]}>
                                                    {a[1]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { !isMobile && value === 'star' && 
                    <div>
                        <div className="filter-header">
                            <div>
                                <div>필터 선택</div>
                                <div className="filter-button-wrapper wide">
                                    <div className={`dropdown-button ${shineFilter && 'shine'}`}>
                                        <div className="dropdown-filter">
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    value={mobileFilterPoliticalStance}
                                                    onChange={this.updateFilterPoliticalStance}
                                                    input={<Input id="select-multiple"/>}
                                                    renderValue={
                                                        selected => {
                                                            if ( selected.length === 0 ) return "정치성향"
                                                            else return mobileFilterPoliticalStance.join(', ')
                                                        }
                                                    }
                                                    >
                                                        <MenuItem value='모든 정치 성향'>
                                                            모든 정치 성향
                                                        </MenuItem>
                                                    {
                                                        politicalStance.map(stance => (
                                                            <MenuItem key={stance[0]} value={stance[0]}>
                                                                {stance[0]}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={`dropdown-button ${shineFilter && 'shine'}`}>
                                        <div className="dropdown-filter">
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    value={mobileFilterGender}
                                                    onChange={this.updateFilterGender}
                                                    input={<Input id="select-multiple" />}
                                                    renderValue={
                                                        selected => {
                                                            if ( selected.length === 0 ) return "성별"
                                                            else return mobileFilterGender.join(', ')
                                                        }
                                                    }
                                                    >
                                                        <MenuItem value='모든 성별'>
                                                            모든 성별
                                                        </MenuItem>
                                                    {gender.map(g => (
                                                        <MenuItem key={g[0]} value={g[0]}>
                                                            {g[0]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={`dropdown-button ${shineFilter && 'shine'}`}>
                                        <div className="dropdown-filter">
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    value={mobileFilterAge}
                                                    onChange={this.updateFilterAge}
                                                    input={<Input id="select-multiple" />}
                                                    renderValue={
                                                        selected => {
                                                            if ( selected.length === 0 ) return "연령대"
                                                            else return mobileFilterAge.join(', ')
                                                        }
                                                    }
                                                    >
                                                        <MenuItem value='모든 연령대'>
                                                            모든 연령대
                                                        </MenuItem>
                                                    {age.map(a => (
                                                        <MenuItem key={a[0]} value={a[0]}>
                                                            {a[0]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                stereotypes.pro ?
                                <div>
                                    <div>관심 집단으로 필터 </div>
                                    <div className="filter-button-wrapper wide wider">
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            className={'filter-selector'}
                                            onClick={(event) => this.onFilterButtonClick('pro', event)}>
                                            찬성할 것 같은 집단
                                        </Button>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            className={'filter-selector'}
                                            onClick={(event) => this.onFilterButtonClick('con', event)}>
                                            반대할 것 같은 집단
                                        </Button>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            className={'filter-selector'}
                                            onClick={(event) => this.onFilterButtonClick('mine', event)}>
                                            나와 같은 집단
                                        </Button>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            className={'filter-selector'}
                                            onClick={(event) => this.onFilterButtonClick('all', event)}>
                                            전체 의견 보기
                                        </Button>
                                    </div>
                                </div>
                                :
                                <div className="filter-button-wrapper wide wider">
                                </div>
                            }
                            <div className="mode-select"> 
                                <Legend statistics={statistics} filter={filter}></Legend>
                                {/* TODO: Implement this section (change viewing modes)*/}
                                <FormControl>
                                    <Select
                                        value={filter}
                                        onChange={this.handleFilterChange}
                                        input={<Input id="select-multiple" />}
                                        >
                                        {filters.map(_filter => (
                                            <MenuItem key={_filter} value={_filter}>
                                                {_filter}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> 
                            </div>
                        </div>
                        <div className="labels">
                            <div className="left-label"> 매우 반대 </div>
                            <div className="recommendation-container">
                                <div className="item">
                                    <div className="outerCircle different">
                                    </div>
                                    <div>
                                            : 반대 입장의 다른 생각
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="outerCircle opposite">
                                    </div>
                                    <div>
                                            : 반대 입장의 유사한 생각
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="outerCircle similar">
                                    </div>
                                    <div>
                                            : 같은 입장의 다른 생각
                                    </div>
                                </div>
                            </div>
                            <div className="right-label"> 매우 찬성 </div>
                            <div className="axis"></div>
                        </div> 
                    </div>                   
                }
            </div>
        )
    }


    componentWillUnmount() {
        console.log('fire')
        const key = this.props.match.params.key

        this.createLog('issueViewFinished', {
            issue: key,
            condition: 'starview'
        })
        window.removeEventListener('resize', this.timeoutedHandleWindowSizeChange);
        window.removeEventListener('mousedown', this.handleMouseDown);
        document.querySelector('meta[name="viewport"]').setAttribute("content", 'width=device-width, maximum-scale=1');
    }

    handleMouseDown = (event) => {
        if ( event.target && event.target.className )
        {
            if (typeof event.target.className.baseVal === 'string') {
                return
            }
            const _className = event.target.className;
            if (!(( typeof(_className) === 'string') && ( _className.includes('circular') || _className.includes('like-button') || _className.includes('heart') || _className.includes('text') || _className.includes('command') || _className.includes('Mui') || _className.includes('outerCircle') || _className.includes('freeform-input')))) {

                this.setState({
                    drawData: null,
                    hoverTop: 0,
                    hoverLeft: 0,
                    hoverId: null,
                })
            }
        }
    }

    handleWindowSizeChange = () => {
        const { isMobile } = this.props;
        if ( !(isMobile) ) {
            const width = document.getElementsByClassName('opinion-container')[0].offsetWidth;
            const height = document.getElementsByClassName('opinion-container')[0].offsetHeight;
            this.setState({ width: width, height: height });
        }
    };

    onLikeClickHandler(data, idx) {
        const { _id, liked } = data;
        console.log("like clicked", data, "liked", !liked);
        if (!liked) {
            this.createLog('OpinionLiked', {
                opinionId: _id,
                liked: !liked
            })
        } else {
            this.createLog('opinionUnliked', {
                opinionId: _id,
                liked: !liked
            })
        }

        const { opinion, token } = this.props;
        const { opinions, myOpinion } = this.state;
        if (!liked) {
            fetch(`${process.env.REACT_APP_URL}/dev/opinions/addEdge`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                    authorOpinionId: myOpinion._id, 
                    likedOpinionId: _id, 
                })
            })
            .then(() => {
                if (myOpinion._id === _id) {
                    this.setState({
                        myOpinion: {...myOpinion, liked: true, likeCount: myOpinion.likeCount + 1}
                    })
                }
                for (var i = 0; i < opinions.length; ++i) {
                    if (opinions[i]._id === _id) {
                        opinions[i].liked = true;
                        opinions[i].likeCount += 1
                        this.setState({
                            opinions: opinions
                        })
                    }
                }
            })
        }
        else {
            fetch(`${process.env.REACT_APP_URL}/dev/opinions/removeEdge`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                    authorOpinionId: myOpinion._id, 
                    unlikedOpinionId: _id, 
                })
            })
            .then(() => {
                if (myOpinion._id === _id) {
                    this.setState({
                        myOpinion: {...myOpinion, liked: false, likeCount: myOpinion.likeCount - 1}
                    })
                }
                for (var i = 0; i < opinions.length; ++i) {
                    if (opinions[i]._id === _id) {
                        opinions[i].liked = false;
                        opinions[i].likeCount -= 1
                        this.setState({
                            opinions: opinions
                        })
                    }
                }
            })
        }
    }

    setEditing = (data) => {
        const { isEditing, newStance, opinions, myOpinion } = this.state
        const { token, issue, option } = this.props
        if (isEditing) {
            this.createLog('updateOpinion', {
                prevOpinion: data,
                newOpinion: {
                    issue: issue,
                    stance: newStance,
                    argument: option
                }
            })
            fetch(`${process.env.REACT_APP_URL}/dev/opinions?issueId=${issue}`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    stance: newStance, // 정책에 대한 5-point Likert scale (1: 매우 반대, 5: 매우 찬성)
                    argument: option, // "왜 그렇게 생각하셨나요?"에 대한 대답.
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    this.setState({
                        opinions: opinions.map(
                            (o) => o.mine
                                ? {...response, 
                                    mine: o.mine, 
                                    author: o.author, 
                                    likeCount: response.likedBy.length,
                                    liked: response.likes.includes(o._id)}
                                : o
                        ),
                        myOpinion: {
                            ...response,
                            mine: myOpinion.mine,
                            author: myOpinion.author,
                            likeCount: response.likedBy.length,
                            liked: response.likes.includes(myOpinion._id)
                        }
                    })
                    this.setState({
                        drawData: null,
                        hoverTop: 0,
                        hoverLeft: 0,
                        hoverId: null,
                    })
                })
        }
        this.setState({
            isEditing: !isEditing
        })
    }

    resultBox(data, top, left, idx) {
        if (!data) {
            return null
        }
        if (!data.author) {
            console.log(data)
        }
        const { mobileFilterAge, mobileFilterGender, mobileFilterPoliticalStance, mobileFilterStance, isEditing, opinions, myOpinionIdx, newArgument } = this.state;
        const { token, issue, option } = this.props;
        const _top = top ? top : 0;
        const _left = left ? left : 0;
        const relative = top ? 'absolute' : '';
        const marks = ['매우 반대', '반대', '중립', '찬성', '매우 찬성']
        const myMatch = data ? this.checkSharedIdentity(data) : false
        // const onTextBoxChange = (e) => {
        //     this.setState({
        //         newArgument: e.target.value
        //     })
        // }

        const myStyle = {
            ...(top >= 0 && {top:_top}), 
            ...(top < 0 && {bottom: (-1 * _top)}), 
            left: _left, 
            position: relative
        }

        const handleSliderChange = (e, newValue) => {
            this.setState({
                newStance: newValue
            })
        }
        return (
            data && data.author &&
                ((mobileFilterAge[0] === '연령대별') || (mobileFilterAge.includes('모든 연령대')) || (mobileFilterAge.includes(data.author.age) )) &&
                ((mobileFilterGender[0] === '성별') || (mobileFilterGender.includes('모든 성별')) || (mobileFilterGender.includes(data.author.gender))) &&
                ((mobileFilterPoliticalStance[0] === '정치성향별') || (mobileFilterPoliticalStance.includes('모든 정치 성향')) || (mobileFilterPoliticalStance.includes(data.author.politicalStance))) &&
                ((mobileFilterStance[0] === '입장별') || (mobileFilterStance[0] === '전체') || (mobileFilterStance[0] === data.stance))
                ?
                <div key={data._id + data.stance + data.argument} className={`result-box ${data.mine && isEditing && 'edit'}`} style={myStyle}>
                    <div className="opinion-box">
                        <div className="opinion white">
                        {
                            data.mine &&
                            <div className="opinion-header">
                                <div>
                                    [내 생각]
                                </div>
                                <div onClick={() => this.setEditing(data)}>
                                    {isEditing ? 
                                    <CheckIcon className="command-div"/> : 
                                        (
                                            !data.argument ? 
                                            <div className="command-div">
                                                <div className="command">
                                                생각 남기기
                                                </div>
                                                <EditIcon/>
                                            </div>
                                            : 
                                            <div className="command-div">
                                                <div className="command">생각 수정하기
                                                </div>
                                                <EditIcon></EditIcon>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {
                            !data.mine && <table className="demographics">
                                <tbody>
                                    <tr>
                                        <th>정치성향</th>
                                        <th>연령대</th>
                                        <th>성별</th>
                                        <th>직업</th>
                                    </tr>
                                    <tr>
                                        <td className={`${myMatch && myMatch.includes('politicalStance') ? 'match' : ''}`}>{data.author.politicalStance}</td>
                                        <td className={`${myMatch && myMatch.includes('age') ? 'match' : ''}`}>{data.author.age}</td>
                                        <td className={`${myMatch && myMatch.includes('gender') ? 'match' : ''}`}>{data.author.gender}</td>
                                        <td className={`${myMatch && myMatch.includes('occupation') ? 'match' : ''}`}>{data.author.occupation}</td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        {
                            (data.mine && isEditing) ?
                            <div className="command-edit">
                                <Slider
                                    className='likertscale'
                                    defaultValue={data.stance}
                                    aria-labelledby="discrete-slider-custom"
                                    valueLabelDisplay="auto"
                                    onChange={handleSliderChange}
                                    min={1}
                                    max={5}
                                    marks={marks.map((value,i) => {
                                            return {value: i+1, label: value}
                                        })
                                    }></Slider>
                                <Response className="text" responseType={"freeform"} defaultValue={data.argument}/>
                            </div> :
                            <div className="opinion-text">
                                { data.argument }
                            </div>
                        }
                        {
                            data.mine && !data.argument && !isEditing && 
                                <div className="opinion-text text-center placeholder">'생각 남기기'를 누르시고 이 이슈에 대해  <br></br>그렇게 생각하시는 이유를 알려주세요.</div>
                        }
                        </div>
                        {!(data.mine && isEditing) && <ReadOnlySlider id="result-slider" marks={marks} value={data.stance}></ReadOnlySlider>}
                        <div className="divider" />
                        <div className="like text-center">
                            <div className="text">{data.likeCount}명이 공감하였습니다.</div>
                            <div className="like-button" onClick={() => this.onLikeClickHandler(data, idx)}>
                            <div className={`heart ${data.liked ? 'heart-selected' : 'heart-unselected'}`}/>
                                <div className="text">공감</div>
                            </div>
                        </div>
                    </div>
                </div>
            :
            null
    )}

    componentDidMount() {
        const { match, token } = this.props;
        const { select, saveIssueTitle, saveIssueKey, selectoption, stereotypes, save_stereotypes } = this.props;

        const key = match.params.key
        let url = ''

        console.log(stereotypes)

        selectoption('')

        // document.querySelector('meta[name="viewport"]').setAttribute("content", 'width=device-width, initial-scale=1, maximum-scale=1');

        // if (issue) {
        //     url = `${process.env.REACT_APP_URL}/dev/opinions?issueId=${issue}&opinionId=${opinion}`
        // } else  

        if (!token) {
            const emailId = Math.random().toString(36).substring(2);
            const kakaoId = Math.random().toString(9).substring(7);
            const email = emailId + '@dummy.com';
            ReactGA.set({ userId: kakaoId });

            fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    email: email, 
                    kakaoId: kakaoId,
                })
            })
            .then((res) => res.json())
            .then((response) => {
                this.props.save_token(response.token);
                localStorage.setItem('dummy', true);
                // this.props.history.push('/consent');
            }, (error) => {
                if (error) {
                    console.log(error);
                }
            })
        }
        if (match.params.key) {
            url = `${process.env.REACT_APP_URL}/dev/opinions?key=${key}`

            fetch(`${process.env.REACT_APP_URL}/dev/issues/key?key=${key}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => res.json())
                .then(response => {
                    select(response.issue._id  || null)
                    saveIssueTitle(response.issue.title || null)
                    saveIssueKey(response.issue.key || null)
                    this.setState({
                        prevOpinion: response.prevOpinion
                    })
                    localStorage.setItem('prevOpinion', JSON.stringify(response.prevOpinion));
                })
        }

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json'
            }
        })
            .then((res) => res.json())
            .then((response) => 
                this.setState({opinions: response, loading: false},
                    () => {
                        const { opinions } = this.state;
                        const opinionCount = opinions.length;
                        const genderCount = {'여성': 0, '남성': 0, '그 외': 0, '대답을 원치 않음': 0};
                        const ageCount = {'10대': 0, '20대': 0, '30대': 0, '40대': 0, '50대': 0, '60대': 0, '70대 이상': 0};
                        const stanceCount = {'보수': 0, '중도보수': 0, '중도': 0, '중도진보': 0, '진보': 0, '잘 모름/대답을 원치 않음': 0, };
                        opinions.length > 0 && opinions.map(data => {
                            if (data.author) {
                                genderCount[data.author.gender] = genderCount[data.author.gender]+1;
                                ageCount[data.author.age] = ageCount[data.author.age]+1;
                                stanceCount[data.author.politicalStance] = stanceCount[data.author.politicalStance]+1;    
                            }
                            return 0;
                        })
                        const myOpinionIdx = opinions.findIndex(o => o.mine)
                        if (myOpinionIdx >= 0) {
                            const myOpinion = opinions[myOpinionIdx]
                            const stance = myOpinion.stance-1;
                            const left = stance>0 ? width/5*stance + width/5*(myOpinion.x/500) : width/5*(myOpinion.x/500);
                            const top = height*(myOpinion.y/1000) ? height*(myOpinion.y/1000) : 0;
                            const hoverLeft = width/2 < left ? left-256-20 : left;
                            const hoverTop = height/2 < top ? top-256-20 : top;
                            this.setState({
                                drawData: myOpinion,
                                hoverTop: hoverTop,
                                hoverLeft: hoverLeft,
                                hoverId: myOpinion._id,
                                idx: myOpinionIdx,
                                newStance: myOpinion.stance,
                                myOpinion: myOpinion,
                                myOpinionIdx: myOpinionIdx,
                                // mobileFilterAge: [myOpinion.author.age],
                                // mobileFilterGender: [myOpinion.author.gender],
                                // mobileFilterPoliticalStance: [myOpinion.author.politicalStance],
                                differents: myOpinion.differents || [],
                                similarities: myOpinion.similarities || [],
                                opposites: myOpinion.opposites || []
                            })
                        }
                        
                        this.setState({
                            width: width - 24,
                            height: height - 24,
                            statistics: {
                                opinionCount: opinionCount,
                                genderCount: genderCount,
                                ageCount: ageCount,
                                stanceCount: stanceCount,
                            }
                        })
                    }
            ))

        if (!stereotypes.issueKey || stereotypes.issueKey !== key) {
            fetch(`${process.env.REACT_APP_URL}/dev/expectations?key=${key}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => res.json())
                .then(response => {
                    const stereotype = {
                        issueKey: key,
                        pro: response.pro,
                        con: response.con
                    }
                    save_stereotypes(stereotype)
                })
        }

        const { isMobile } = this.props;
        const UA = navigator.userAgent

        this.setState({
            enableMobileView: UA.includes('Mobi')
        })
        var width, height;
        if (isMobile) {    
            width = 0; 
            height = 0;
        }
        else { 
            width = document.getElementsByClassName('opinion-container')[0].offsetWidth;
            height = document.getElementsByClassName('opinion-container')[0].offsetHeight;
        }
        this.createLog('IssueViewed', {
            issue: key,
            condition: 'starview'
        })
        window.addEventListener('resize', this.timeoutedHandleWindowSizeChange);
        window.addEventListener('mousedown', this.handleMouseDown);
    }

    timeoutedHandleWindowSizeChange = () => {
        return setTimeout(this.handleWindowSizeChange, 100)
    }

    setButtonHovered(bl) {
        this.setState({
            buttonIsHovered: bl
        })
    }

    filterOnClickHandler(item, e) {
        const filterList = {
            '보수': 'ex-r', '중도보수': 'r', '중도': 'n', '중도진보': 'l', '진보': 'ex-l','잘 모름/대답을 원치 않음': 'not',
            '여성': 'female', '남성': 'male', '그 외': 'un',
            '10대': 'g10', '20대': 'g20','30대': 'g30','40대': 'g40','50대': 'g50','60대': 'g60','70대 이상': 'g70'
        }
    }

    graphOnClickHandler(filterType, item,e) {
        const { filterItem } = this.state
        console.log('donut graph segment is clicked', item, 'active?', !(filterItem === item));
        // console.log(e)

        if (filterType === '성별') {
            this.createLog('UpdateDesktopFilterGender', {
                changedTo: item,
                isActive: !(filterItem === item)
            })
        } else if (filterType === '정치성향별') {
            this.createLog('UpdateDesktopFilterPoliticalStance', {
                changedTo: item,
                isActive: !(filterItem === item)
            })
        } else {
            this.createLog('UpdateDesktopFilterAge', {
                changedTo: item,
                isActive: !(filterItem === item)
            })
        }

        if (!(filterItem === item)) {
            this.setState({
                filterItem: item
            })
        } else {
            this.setState({
                filterItem: ''
            })
        }

        // const filterList = {
        //     '보수': 'ex-r', '중도보수': 'r', '중도': 'n', '중도진보': 'l', '진보': 'ex-l','잘 모름/대답을 원치 않음': 'not',
        //     '여성': 'female', '남성': 'male', '그 외': 'un',
        //     '10대': 'g10', '20대': 'g20','30대': 'g30','40대': 'g40','50대': 'g50','60대': 'g60','70대 이상': 'g70'
        // }
        // if (e.target.classList.contains('active')) {
        //     Array.from(document.querySelectorAll(`.circular:not(.filter):not(.list):not(.${filterList[item]})`)).forEach((elm, idx) => {
        //         elm.parentElement.style.display = ''
        //     });
        //     e.target.classList.remove('active');
        // }
        // else {
        //     Array.from(document.querySelectorAll(`.circular:not(.filter):not(.list):not(.${filterList[item]})`)).forEach((elm, idx) => {
        //         elm.parentElement.style.display = 'none';
        //     });
        //     e.target.classList.add('active');
        // }
    }

    drawGraph(filter) {
        const colorSet = {'보수': "#ff71a9", '중도보수': "#ff8975", '중도': "#fff368", '중도진보': "#86daff", '진보': "#29a3ff", '잘 모름/대답을 원치 않음': "#7B7B7B",
                        '여성': "#ff705a", '남성': "#63bcff", '그 외': "#fff25d", '대답을 원치 않음': '#7b7b7b', 
                        '10대': '#ffffff', '20대': "tomato", '30대': "orange", '40대': "dodgerblue", '50대': "mediumseagreen", '60대': "slateblue", '70대 이상': 'violet'}

        var totalOffset = 0;
        const initialOffset = 25;
        const { statistics, filterItem } = this.state;
        var list = [];
        var strokeDashoffset=0;
        var strokeDasharray='0 0';
        const opinionCount = statistics.opinionCount;
        if (filter[0] === '정치성향별') list = statistics.stanceCount;
        else if (filter[0] === '성별') list = statistics.genderCount;
        else list = statistics.ageCount;

        return (
            <svg width="70%" height="70%" viewBox="0 0 42 42" className="donut">
                {
                    opinionCount !== 0 ?
                    Object.keys(list).map((item, idx) => {
                        if (list[item] !== 0) {
                            if (idx === 0) {
                                strokeDashoffset = initialOffset
                            } else { 
                                strokeDashoffset = (100 - totalOffset + initialOffset > 100 ?
                                                    100 - totalOffset + initialOffset - 100 : 
                                                    100 - totalOffset + initialOffset).toString();
                            }
                            var currentOffset = list[item] / opinionCount * 100;
                            var remainingOffset = 100 - currentOffset;
                            totalOffset = totalOffset + currentOffset;
                            strokeDasharray = `${currentOffset} ${remainingOffset}`;
                            return <circle key={idx}
                                className={`donut-segment ${filterItem === item ? 'active' : ''}`}
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="none"
                                stroke={colorSet[item]}
                                strokeWidth="6"
                                strokeDasharray={strokeDasharray}
                                style={{ strokeDashoffset: strokeDashoffset }}
                                onClick={(e) => this.graphOnClickHandler(filter[0], item, e)} />
                        }
                        return 0;
                    })
                    : null
                }

            </svg>
        )
    }    

    setButtonClicked() {
        const { buttonIsClicked } = this.state;
        this.setState({
            buttonIsClicked: !buttonIsClicked
        })
        this.props.onClick(!buttonIsClicked);
    }

    handleChange(event) {
        console.log('the view mode changed to: ', event.target.value);
        this.createLog('ViewModeChanged', {
            changedTo: event.target.value,
        })
        this.setState({value: event.target.value});
    }

    handleFilterChange(event) {
        console.log('filter on the left set to', event.target.value);
        this.createLog('FilterChanged', {
            changedTo: event.target.value,
        })
        this.setState({
            filter: [event.target.value],
        })
    }

    scrollToTop = (event) => {
        const target = document.getElementById('target')
        target.scrollIntoView({behavior: 'smooth', block: 'center'})
        // scroll.scrollToTop()
        console.log('asdf')
        // document.getElementsByClassName('relaxed-container')[0].scrollTo(0, 0);
    }

    shareDialog = () => {
        console.log('asdf')
        this.setOpen(true)
    }

    onCopied = () => {
        this.setState({
            copied: true
        })
    }

    onCopyDialogClose = () => {
        this.setOpen(false)
        this.setState({
            copied: false
        })
    }

    checkSharedIdentity = (o) => {
        const { myOpinion } = this.state

        if (!myOpinion.author) {
            return false
        }

        const myMatch = []

        if (!o.author || !o.author || !o.author.age) {
            console.log(o)
        }

        if (o.author.age === myOpinion.author.age) {
            myMatch.push('age')
        }
        if (o.author.gender === myOpinion.author.gender) {
            myMatch.push('gender')
        }
        if (o.author.politicalStance === myOpinion.author.politicalStance) {
            myMatch.push('politicalStance')
        }

        if (o.author.occupation === myOpinion.author.occupation) {
            myMatch.push('occupation')
        }

        return myMatch.length && myMatch
    }

    render() {
        const { isMobile } = this.props;
        const { opinions, value, filters, filter, width, idx, open, 
            height, drawData, hoverTop, hoverLeft, hoverId, clickedOpinions, clickedId, similarities,
            differents, opposites, mobileFilterAge, mobileFilterGender, mobileFilterPoliticalStance, mobileFilterStance,
            filterItem, loading, copied, prevOpinion, myOpinion, myOpinionIdx, enableMobileView } = this.state;
        const filteredOpinions = opinions ? opinions.filter(o => {
            return (o.argument && o.argument.length >= 10) 
        }) : []
            return (
            <div className="outer-container">
                <Header isMobile={isMobile} 
                    enableMobileView={enableMobileView} 
                    onShareClick={this.shareDialog}
                    onMobileViewToggleClick={this.toggleMobileView}/>
                <EventDialog></EventDialog>
                { isMobile ?
                    <div className="relaxed-container">
                        {this.resultHeader(filter, filters)}
                        <div className="opinion-container">
                            <div name="target" id="target"></div>
                            {myOpinion && this.resultBox(myOpinion, null, null, myOpinionIdx)}
                            {   
                                (filteredOpinions.length>0 && !loading) ?
                                opinions.filter(o => {
                                    return (o.argument && o.argument.length >= 10 && !o.mine)
                                }).map((value, idx) => {
                                    return this.resultBox(value, null, null, idx)
                                })
                                :
                                (
                                    loading ? 
                                    <div className="circular-progress-wrapper">
                                        <CircularProgress color="secondary"/>
                                    </div>
                                    :
                                    <div className="no-opinion">
                                        의견이 아직 없습니다 :( 
                                            <br></br>
                                        '{prevOpinion ? (!prevOpinion.argument && '생각 남기기' ) : '내 생각 남기기'}'에서 이슈에 대한 자세한 생각을 남겨주세요!
                                    </div>
                                )
                            }
                            <Fab color="primary" size="small" onClick={this.scrollToTop}><UpIcon /></Fab>
                        </div>
                        <ShareDialog onClose={this.onCopyDialogClose} open={open} copied={copied} onCopied={this.onCopied}/>
                    </div>
                    :
                    <div className="relaxed-container">
                        <Grid container spacing={2}>
                            <Grid className="opinion-visualization" item xs={12}>
                                {this.resultHeader(filter, filters)}
                                {
                                    value === 'list' ?
                                    <div className="opinion-container justify-center">
                                        {myOpinion && this.resultBox(myOpinion, null, null, myOpinionIdx)}
                                        {
                                            (filteredOpinions.length > 0 && !loading) ?
                                            (
                                                opinions.filter(o => {
                                                    return (o.argument && o.argument.length >= 10) || o.mine
                                                }).filter((value, idx) => {
                                                    if (!filterItem || // empty filter item
                                                        (filter[0] === '정치성향별' && value.author.politicalStance === filterItem) || // 정치성향 match
                                                        (filter[0] === '성별' && value.author.gender === filterItem) ||
                                                        (filter[0] === '연령대별' && value.author.age === filterItem)) {
                                                            if (value.mine) {
                                                                return false
                                                            }
                                                            return true
                                                    }
                                                }).map((value, idx) => {
                                                    return this.resultBox(value, null, null, idx)
                                                })
                                            )
                                            :
                                            (
                                                loading ? 
                                                <div className="circular-progress-wrapper">
                                                    <CircularProgress color="secondary"/>
                                                </div>
                                                :
                                                <div className="no-opinion">
                                                    의견이 아직 없습니다 :( 
                                                        <br></br>
                                                    '{prevOpinion ? (!prevOpinion.argument && '생각 남기기' ) : '내 생각 남기기'}'에서 이슈에 대한 자세한 생각을 남겨주세요!
                                                </div>
                                            )
                                        }  
                                    </div>
                                    :
                                    <div className="opinion-container">
                                        {this.resultBox(drawData, hoverTop, hoverLeft, idx)}
                                        {   height && width && opinions.length >0 ?
                                            (
                                                opinions.map((data, idx) => {
                                                    if (data.author && (data.mine || data.argument.length >= 30)) {
                                                        if (data.mine || (((mobileFilterAge[0] === '연령대별') || (mobileFilterAge.includes('모든 연령대')) || (mobileFilterAge.includes(data.author.age))) &&
                                                        ((mobileFilterGender[0] === '성별') || (mobileFilterGender.includes('모든 성별')) || (mobileFilterGender.includes(data.author.gender))) &&
                                                        ((mobileFilterPoliticalStance[0] === '정치성향별') || (mobileFilterPoliticalStance.includes('모든 정치 성향')) || (mobileFilterPoliticalStance.includes(data.author.politicalStance))) &&
                                                        ((mobileFilterStance[0] === '입장별') || (mobileFilterStance[0] === '전체') || (mobileFilterStance[0] === data.stance)))) {
                                                            var circularType = '';
                                                            const stance = data.stance-1;
                                                            const left = stance>0 ? width/5*stance + width/5*(data.x/500) : width/5*(data.x/500);
                                                            const top = height*(data.y/1000) ? height*(data.y/1000) : 0;
                                                            const hoverLeft = width/2 < left ? left-350-20 : left;
                                                            const hoverTop = height/2 < top ? (top - height - 40) : top;
                                                            if (filter[0] === '정치성향별') circularType = data.author.politicalStance ==='보수' ? 'ex-r' : data.author.politicalStance ==='중도보수' ? 'r' : data.author.politicalStance ==='중도' ? 'n' : data.author.politicalStance ==='중도진보' ? 'l' : data.author.politicalStance ==='진보' ? 'ex-l' : 'not';
                                                            else if (filter[0] === '성별') circularType = data.author.gender ==='여성' ? 'female' : data.author.gender ==='남성' ? 'male' : data.author.gender ==='그 외' ? 'un' : 'not';
                                                            else if (filter[0] === '연령대별') circularType = data.author.age ==='60대' ? 'g60' : data.author.age ==='10대' ? 'g10' : data.author.age ==='20대' ? 'g20' : data.author.age ==='30대' ? 'g30' : data.author.age ==='40대' ? 'g40' : data.author.age ==='50대' ? 'g50' :'g70'
                                                            return (
                                                            <div key={data._id} 
                                                                className={`position-absolute outerCircle 
                                                                ${data._id === hoverId ? 'active' : ''} 
                                                                ${clickedId === data._id ? 'active' : ''} 
                                                                ${data.mine ? 'mine' : ''} 
                                                                ${differents.includes(data._id) ? 'different' : ''}
                                                                ${opposites.includes(data._id) ? 'opposite' : ''}
                                                                ${similarities.includes(data._id) ? 'similar' : ''} `} 
                                                                style={{top:top,left:left}} 
                                                                onMouseEnter={() => this.onMouseEnterHandler(data, hoverTop, hoverLeft)} 
                                                                onMouseLeave={() => this.onMouseLeaveHandler()}
                                                                onClick={() => this.onStarClickHandler(data._id)}>
                                                                <div 
                                                                    className={`circular ${circularType}
                                                                    ${opposites.includes(data._id) ? 'opposite' : ''}
                                                                    ${clickedOpinions.includes(data._id) ? 'opaque' : ''}`}>
            
                                                                </div>
                                                            </div>)
                                                        }
                                                    }
                    //                              return <div key={data._id} className={`position-absolute circular ${circularType} ${data._id === hoverId ? 'active' : ''}`} style={{top:top,left:left}} onMouseEnter={() => this.onMouseEnterHandler(data, hoverTop, hoverLeft, idx)} onMouseLeave={() => this.onMouseLeaveHandler()} onClick={() => this.onStarClickHandler(data._id)}/>
                                                })   
                                            )
                                            :
                                            (
                                                loading ? 
                                                <div className="circular-progress-wrapper">
                                                    <CircularProgress color="secondary"/>
                                                </div>
                                                :
                                                <div className="no-opinion">
                                                    의견이 아직 없습니다 :(
                                                </div>
                                            )
                                        }   
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </div>
                }
                {/* {
                    isMobile ? 
                    <Fab color="secondary" size="small" onClick={this.scrollToTop}><UpIcon /></Fab>
                    : null
                } */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteScreen);