import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { saveOpinion, saveProfile, saveAPIToken } from '../../actions';
import ReadOnlySlider from '../../components/ReadOnlySlider'
import CircularProgress from '@material-ui/core/CircularProgress'
import EventDialog from '../../components/EventDialog'

const mapStateToProps = (state) => {
    const { setMediaFilter, selectOption, saveIssueKey, saveAPIToken } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        option: selectOption,
        issueKey: saveIssueKey,
        token: saveAPIToken,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        opinion: (opinion) => dispatch(saveOpinion(opinion)),
        save_profile: (profile) => dispatch(saveProfile(profile)),
        save_token: (token) => dispatch(saveAPIToken(token))
    }
}

class CompleteScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: false,
            opinions: [],
            marks: ['매우 반대', '반대', '중립', '찬성', '매우 찬성']
        }
        this.login = this.login.bind(this);
        this.successHandler = this.successHandler.bind(this);
        this.fetchMyOpinions = this.fetchMyOpinions.bind(this)
        this.clickIssue = this.clickIssue.bind(this)
        // () => this.props.history.push(`/result/${o.issue.key || o.issueKey}`)
    }

    async fetchMyOpinions() {
        const { token } = this.props

        return fetch(`${process.env.REACT_APP_URL}/dev/opinionsMine`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => {
            if (response.opinions.length < 2) {
                return false
            }
            this.setState({
                opinions: response.opinions
            })
            localStorage.setItem('myOpinions', JSON.stringify(response.opinions));
            return true
        })
    }

    async componentDidMount() {
        const dummy = localStorage.getItem('dummy');
        this.setState({
            dummy: dummy
        })
        // console.log('Hello from NewComplete!')
        const tempOs = JSON.parse(localStorage.getItem('myOpinions'))

        this.setState({
            opinions: tempOs
        })

        const timer1 = setTimeout(async () => {
            const success = await this.fetchMyOpinions()

            if (!success) {
                const timer2 = setTimeout(this.fetchMyOpinions, 2000)
                this.setState({
                    timer: timer2
                })
            }
        }, 1000)
        
        this.setState({
            timer: timer1
        })
    }

    clickIssue (o, event) {
        if (!o.expectation || o.expectation.length === 0) {
            this.props.history.push(`/expect/${o.issue.key || o.issueKey}`)
        } else {
            this.props.history.push(`/result/${o.issue.key || o.issueKey}`)
        }
    }

    componentWillUnmount() {
        const { timer } = this.state
        clearTimeout(timer)
    }

    login() {
        window.Kakao.Auth.login({
            scope: 'account_email',
            success: (res) => {
                console.log(res);
                this.successHandler(res)
            },
            fail: function(error) {
                console.log(error);
            }
          });
    }

    successHandler(res) {
        const { issueKey, opinion, token } = this.props;
        this.setState({
            access_token: res.access_token,
            refresh_token: res.refresh_token,
        });
        window.Kakao.API.request({
            url: '/v2/user/me',
            success: (res) => {
                if (res.kakao_account.email_needs_agreement) {
                    alert('사용자 식별 및 필요한 경우 연락드리기 위해 이메일 주소가 필요합니다. 이메일 주소 제공에 동의해주세요!')
                    return false
                }
                this.setState({
                    id: res.id,
                    name: res.properties.nickname,
                    email: res.kakao_account.email,
                });
                this.props.save_profile({
                    access_token: this.state.access_token,
                    id: res.id,
                    name: res.properties.nickname,
                    email: res.kakao_account.email,
                });
                fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                    method: 'put',
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        newEmail: res.kakao_account.email, 
                        newKakaoId: res.id, 
                        kakaoAccessToken: this.state.access_token,
                        kakaoRefreshToken: this.state.refresh_token,
                        consentAgreed: true
                        // age: users.age,
                        // gender: users.gender,
                        // occupation: users.occupation,
                        // politicalStance: users.politicalStance
                    }) // send age, gender, occupation and politicalStance together to reduce one API call
                })
                .then((res) => res.json())
                .then((response) => {
                    // save the new token from response.token
                    const new_token = response.token
                    this.props.save_token(new_token);
                    localStorage.setItem('dummy', false);
                    this.setState({
                        dummy: false
                    })
                    // // save user info (if it is used in the system) from response.user
                    console.log(response);
                    // const users = localStorage.getItem('users');
                    // const opinions = localStorage.getItem('opinions');
                    // if( users ) {
                    //     fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                    //         method: 'put',
                    //         // credentials: 'include',
                    //         headers: {
                    //             'Content-Type': 'application/json',
                    //             'Authorization': `Bearer ${new_token}`
                    //         },
                    //         body: JSON.stringify(users)
                    //     })
                    // }
                    // if ( opinions ) {
                    //     fetch(`${process.env.REACT_APP_URL}/dev/opinions`, {
                    //         method: 'post',
                    //         headers: {
                    //             'Content-Type': 'application/json',
                    //             'Authorization': `Bearer ${new_token}`
                    //         },
                    //         body: JSON.stringify(opinions)
                    //     })
                    //         .then((res) => res.json())
                    //         .then((response) => {
                    //             console.log(response);
                    //             opinion(response._id);
                    //         })  
                    // }
                    // this.props.history.push(`/result/${issueKey}`)
                }, (error) => {
                    if (error) {
                        console.log(error);
                    }
                })
            },
            fail: function(error) {
                console.log(error);
            }
        })
    }
    render() {
        const { isMobile, issueKey, token } = this.props;
        const { dummy, opinions, marks } = this.state;
        return (
            <div className="wrapper">
                <Header />
                <EventDialog></EventDialog>
                <div className="container">
                    
                    <div className="title">
                        응답해주셔서 감사합니다.
                        <br></br>
                        이슈 이름을 선택해 다른 사람들의 생각을 알아보세요.
                    </div>
                    {
                        opinions && opinions.length > 0 ? 
                        opinions.map(o => {
                            console.log(o.issue._id || o.issue, o.stance)
                            return (
                                (o.issue.key === '게임중독-질병분류') && (
                                    <div key={`${o.issue._id || o.issue} ${o.stance}`} className="issueBox">
                                        <div className={`text-center issue ${o.numOpinions >= 200 ? 'hot' : ''}`} >
                                            <span className="underline link" onClick={(e) => this.clickIssue(o, e)}>{o.issue.title || o.issueTitle}</span>
                                        </div>
                                        <ReadOnlySlider marks={marks} value={o.stance} />
                                    </div>
                                )
                            )
                        })   
                        : 
                        (<div className="circular-progress-wrapper">
                            <CircularProgress color="secondary"/>
                        </div>)
                    }
                    <div className="bottom-text">
                        {dummy === 'true' && isMobile ? '카카오 로그인을 하시면 응답하신 내용을 저장하실 수 있고 맞춤형 결과를 보실 수 있습니다.'
                            : '' }
                    </div>
                </div>
                <div className="bottom-text">
                    {dummy === 'true' && !isMobile ? '카카오 로그인을 하시면 응답하신 내용을 저장하실 수 있고 맞춤형 결과를 보실 수 있습니다.'
                        : '' }
                </div>
                {
                    dummy === 'true' ? 
                    
                    <Button type="button-gradient button sm" content={"카카오 계정으로 로그인"} onClick={() => this.login()}/> :
                    null
                }
                {/* {
                    isMobile ? 
                    <div className="next">
                        <Button type="button-transparent button sm" content={dummy === 'true' ? '그냥 결과보기' : '결과보기'} onClick={() => this.props.history.push(`/result/${issueKey}`)}/>
                    </div>
                    :
                    <Button type="button-transparent button sm" content={dummy === 'true' ? '그냥 결과보기' : '결과보기'} onClick={() => this.props.history.push(`/result/${issueKey}`)}/>
                } */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteScreen);